<template>
    <div class="cusModal" v-if="showtable">
        <div class="cusModalCont">
            <div class="cusModalTitle backBlack">
                {{ lang.chose_technical_or_more }}
            </div>
            <div class="cusModalBody">
                <v-row style="margin:10px;">
                    <v-col cols="12" md="3" v-for="(item, index) in users" :key="index">
                        <b-form-checkbox
                            :id="`checkbox_${index}`"
                            :ref="`item_${item.value}`"
                            style="padding:3px;border:1px solid #000;"
                            @change="addToTech(item.value, index)"
                            :checked="isChecked(item.value)"
                        >
                            <span style="margin:2px;">{{item.text}}</span>
                        </b-form-checkbox>
                    </v-col>
                    <v-col cols="12" style="text-align:inline-end">
                        <b-button style="background:green;" @click="closeModal()">حقظ واغلاق</b-button>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            t_index: 0,
            showtable: false,
            title: '',
            tbrows: [],
            search: {
                sdate: '',
                edate: '',
            },
            source_id: 0,
            account_name: '',
            users: [],
            techs: [],
            type: 1
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    methods: {
        isChecked(value) {
            return this.techs.includes(value);
        },

        addToTech(value)
        {
            // const val = this.$refs[`item_${index}`];
            let index = this.techs.indexOf(value);
            if(index !== -1){
                this.techs.splice(index, 1)
            }else{
                this.techs.push(value)
            }
        },
        closeModal()
        {
            if(this.type == 1){
                this.$parent.$parent.newrow.workers_name = this.techs
            }else if(this.type == 2){
                this.$parent.$parent.tbrows[this.t_index].workers_name = this.techs
            }else if(this.type == 3){
                this.$parent.card.rows[this.t_index].empid = this.techs
            }else if(this.type == 4){
                this.$parent.newrow1.workers_name = this.techs
            }else if(this.type == 5){
                this.$parent.newrow.workers_name = this.techs
            }
            this.showtable = false;
            this.users = []
            this.techs = []
            this.t_index = 0
        }
    },
    created() {
        //
    },
}
</script>
<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:600px;
    height: 400px;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
.eportBtn{
    position: absolute;
    top:2px;
    left:60px;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
    display:flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
.eportBtn div{
    padding:2px 5px;
    width:80px;
    font-size:.8rem;
    background:blue;
    margin-inline-start:5px;
    color:#fff;
    border-radius: 3px;
}
</style>

